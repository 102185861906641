import { Button, Container, FormGroup, Paper } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { RotateLeftSharp } from "@mui/icons-material"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"
import { CalcAndSyncRequest } from "../../apis/ersys-data"
import dayjs from "dayjs"
import { useForm } from "react-hook-form"
import FormInputDatePicker from "../forms/FormInputDatePicker"

const formDefaults: CalcAndSyncRequest = {
    cutoffDate: dayjs().format()
}

const AdministrativeToolsCalcBalancesTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [id, setId] = useState<string>("")
    const [formSpacing] = useState(2)
    const { handleSubmit, control, } =
        useForm<CalcAndSyncRequest>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const calcAndSync = async (req: CalcAndSyncRequest) => {
        setLoading(true)
        try {
            const response = await ersysDataApiServices.bulkService.bulkCalcAndSync(req)
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while rolling up accounts. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <AdministrativeToolsWaitOnProcessModal 
            id={id}  
            onClose={() => setShowWaitingModal(false)} 
            open={showWaitingModal} 
            title="Calculating Previous Balances"
            noCounts
            />
            <Paper sx={{ padding: 2, margin: 2 }}>
                <FormGroup>
                        <FormInputDatePicker 
                            name="cutoffDate"
                            control={control} 
                            label="Cutoff Date" 
                            spacing={formSpacing}                    
                        />
                </FormGroup>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        onClick={handleSubmit(calcAndSync)}
                        endIcon={<RotateLeftSharp />}
                    >
                        Calculate Previous Balances
                    </Button>
                </div>
            </Paper>            
        </Container>
    )
}

export default AdministrativeToolsCalcBalancesTab