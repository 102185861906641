import { FormGroup, Grid, List, ListItem, Skeleton } from "@mui/material"
import { MonthlyPayment } from "../../apis/ersys-data/api"
import { useEffect, useState } from "react"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import AccountSubscriptionComponent from "./AccountSubscriptionComponent"

interface AccountSubscriptionsTabProps {
    formSpacing: number
}

const AccountSubscriptionsTab: React.FC<AccountSubscriptionsTabProps> = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [montlyPayments, setMonthlyPayments] = useState<MonthlyPayment[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    useEffect(() => {
        dispatch(setIsLoading(true))
        const fetch = async () => {
            try {
                var resp = await ersysDataApiServices.accountsService.accountsListMonthlyPayments(parseInt(params.id!, 10))
                setMonthlyPayments(resp.data.payments)
            }
            catch (ex: any) {
                dispatch(
                    showError({
                        title: "Unexpected Error occurred",
                        message:
                            "An unexpected error occurred while getting subscription data. If problem persists please contact IT.",
                    }),
                )
                console.log(ex)
            } finally {
                setLoaded(true)
                dispatch(setIsLoading(false))
            }
        }
        fetch()
    }, [dispatch, setMonthlyPayments, ersysDataApiServices.accountsService, params.id])

    
    const showSkeleton = () => {
        return montlyPayments.length < 1 && !loaded
    }

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <Skeleton variant='rectangular' width={600} height={150} key={`skele-${i}`} sx={{margin: 1}}/>
                ))}
            </FormGroup>
        )
    }

    return (
        <Grid container alignItems="center" justifyContent="center">
            {showSkeleton() ? renderSkeleton() :
                <List sx={{height: 550, overflow: 'auto'}}>
                     {montlyPayments.map((payment) => (
                        <ListItem key={payment.orderNumber}>
                            <AccountSubscriptionComponent payment={payment} />
                        </ListItem>
                     ))}
                </List>
            }
        </Grid>
    )
}

export default AccountSubscriptionsTab