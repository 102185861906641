import { Button, Container, FormGroup, Paper, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../features/errors/Errors"
import { setIsLoading } from "../../features/menus/AppMenu"
import { useErsysDataApi } from "../ErsysDataApiProvider"
import { MergeSharp } from "@mui/icons-material"
import AdministrativeToolsWaitOnProcessModal from "./AdministrativeToolsWaitOnProcessModal"
import { MergeAccountsRequest } from "../../apis/ersys-data"
import { useForm, useFormState } from "react-hook-form"
import FormInputNumber from "../forms/FormInputNumber"

const formDefaults: MergeAccountsRequest = {
    newAccount: 0,
    oldAccount: 0
}

const AdministrativeToolsMergeAccountsTab: React.FC = () => {
    const ersysDataApiServices = useErsysDataApi()
    const [loading, setLoading] = useState<boolean>(false)
    const [showWaitingModal, setShowWaitingModal] = useState<boolean>(false)
    const [id, setId] = useState<string>("")
    const [formSpacing] = useState(2)
    const { handleSubmit, control, } =
        useForm<MergeAccountsRequest>({
            defaultValues: formDefaults,
            reValidateMode: "onSubmit",
        })
    const { isDirty } = useFormState({ control })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setIsLoading(loading))
    }, [loading, dispatch])

    const merge = async (req: MergeAccountsRequest) => {
        setLoading(true)
        try {
            // sigh hack to get around bug
            req.newAccount = parseInt(req.newAccount.toString(), 10)
            req.oldAccount = parseInt(req.oldAccount.toString(), 10)
            const response = await ersysDataApiServices.bulkService.bulkMergeAccounts(req)
            setId(response.data.id)
            setShowWaitingModal(true)
        }
        catch (ex: any) {
            dispatch(
                showError({
                    title: "Unexpected Error occurred",
                    message:
                        "An unexpected error occurred while merging accounts. If problem persists please contact IT.",
                }),
            )
            console.log(ex)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <AdministrativeToolsWaitOnProcessModal 
            id={id}  
            onClose={() => setShowWaitingModal(false)} 
            open={showWaitingModal} 
            title="Merging Accounts"
            noCounts
            />
            <Paper sx={{ padding: 2, margin: 2 }}>
            <Typography>This action is irrevsersible. Please be careful.</Typography>
                <FormGroup>
                        <FormInputNumber
                            name="oldAccount"
                            label="Old Account"
                            control={control}
                            spacing={formSpacing}
                            rules={{ required: true, min: 1}}
                        />
                        <FormInputNumber
                            name="newAccount"
                            label="New Account"
                            control={control}
                            spacing={formSpacing}
                            rules={{ required: true, min: 1}}
                        />
                </FormGroup>
                <div className="edit-user-save-btn">
                    <Button
                        variant="contained"
                        disabled={!isDirty}
                        onClick={handleSubmit(merge)}
                        endIcon={<MergeSharp />}
                    >
                        Merge Accounts
                    </Button>
                </div>
            </Paper>            
        </Container>
    )
}

export default AdministrativeToolsMergeAccountsTab