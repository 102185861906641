import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { OrderCancellationRequest } from "../../../apis/ersys-data"
import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import FormInputNumberIntToDollars from "../../forms/FormInputNumberIntToDollars"

const defaults: OrderCancellationRequest = {
  orderId: 0,
  refundValue: 0,
  taxRefundValue: 0,
}

interface OrderCancellationModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (req: OrderCancellationRequest) => Promise<void>
}

const OrderCancellationModal: React.FC<OrderCancellationModalProps> = ({
  open,
  onCreate,
  onClose,
}) => {
  const [formSpacing] = useState(2)
  const { handleSubmit, reset, control } =
    useForm<OrderCancellationRequest>({
      defaultValues: defaults,
      reValidateMode: "onSubmit",
    })

  const handleCreate = (input: OrderCancellationRequest) => {
    onCreate(input)
  }

  useEffect(() => {
    reset()
  }, [reset, open])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">Cancel order</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <FormInputNumberIntToDollars
                spacing={formSpacing}
                name={"refundValue"}
                control={control}
                label={"Amount to Refund"}
                rules={{ required: "Required Field", min: 0}}
                />
                <FormInputNumberIntToDollars
                  spacing={formSpacing}
                  name={"taxRefundValue"}
                  control={control}
                  label={"Amount to Refund for Tax"}
                  rules={{ required: "Required Field", min: 0}}
                  />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleCreate)} autoFocus>
          Ok
        </Button>
        <Button onClick={() => onClose()} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrderCancellationModal
