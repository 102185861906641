import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { Store } from "../../apis/ersys-data/api"
import { Control } from "react-hook-form"
import FormInputText from "../forms/FormInputText"
import { FormInputDropdown, FormInputDropdownOption } from "../forms/FormInputDropdown"

interface StoresGeneralTabProps {
    control: Control<Store, any>
    showSkeleton: () => boolean,
    formSpacing: number
}

const StoresGeneralTab: React.FC<StoresGeneralTabProps> = ({ control, showSkeleton, formSpacing }) => {
	
		const storeTypeOptions: FormInputDropdownOption[] = [
			{ label: "School", value: "school" },
			{ label: "Summer School", value: "summer_school" },
			{ label: "Camp", value: "camp" },
			{ label: "Retail Store", value: "retail_store" },
			{ label: "Retail Route", value: "retail_route" },
			{ label: "Residential", value: "residential" },
		]

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"prefix"}
                            control={control}
                            label={"Prefix"}
                            rules={{ required: "Required Field", maxLength: 2 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"fullName"}
                            control={control}
                            label={"Full Name"}
                            rules={{ required: "Required Field", maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"shortName"}
                            control={control}
                            label={"Short Name"}
                            rules={{ required: "Required Field", maxLength: 15 }}
                        />
												<FormInputDropdown
                            name="storeType"
                            control={control}
                            label="Store Type"
                            options={storeTypeOptions}
                            spacing={formSpacing}
                        />

                    </FormGroup>)}
            </Grid>

            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"addressLineOne"}
                            control={control}
                            label={"Address Line One"}
                            rules={{ maxLength: 90 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"addressLineTwo"}
                            control={control}
                            label={"Address Line Two"}
                            rules={{ maxLength: 90 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"city"}
                            control={control}
                            label={"City"}
                            rules={{ maxLength: 50 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"state"}
                            control={control}
                            label={"State (Abbrev)"}
                            rules={{ maxLength: 2 }}
                        />
                        <FormInputText
                            spacing={formSpacing}
                            name={"postal"}
                            control={control}
                            label={"Postal"}
                            rules={{ maxLength: 12 }}
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default StoresGeneralTab
