import { Button } from "@mui/material"
import "./BurkePlanComponent.scss"

interface BurkePlanComponentProps {
  id: number
  planId: number
  title: string
  cost: number
  hidePricing: Boolean
  isMonthly: Boolean
  planPicked: (id: number) => Promise<void>
}

const BurkePlanComponent: React.FC<BurkePlanComponentProps> = ({
  id,
  planId,
  title,
  cost,
  hidePricing,
  isMonthly,
  planPicked,
}) => {
  const calculatePrice = () => {
    if (hidePricing) {
      return "Call for Pricing"
    }

    if (cost === undefined || isNaN(cost)) {
      return "0"
    }

    return `${(cost / 100).toFixed(2)}`
  }

  const getClassSuffix = () => {
    switch (planId) {
      case 1: //Look Sharp
      case 27: //Summer Look Sharp
        return "ls"
      case 2: //Just the Basics
      case 29: //Summer Just the Basics
        return "jtb"
      case 8: //Wash dry fold
      case 28: //Summer Wash dry fold
      case 37: //not 699 WDF
        return "wdf"
      case 7: //Premium
        return "pp"
      default: //Other
        return "misc"
    }
  }

  const getHypeText = () => {
    switch (planId) {
      case 1: //Look Sharp
      case 27: //Summer Look Sharp
        return "Most Popular"
      case 2: //Just the Basics
      case 29: //Summer Just the Basics
        return "Time Saver"
      case 8: //Wash dry fold
      case 28: //Summer Wash dry fold
      case 37: //not 699 WDF
        return "New Plan"
      case 7: //Premium
        return "Best Value"
      default: //Other
        return ""
    }
  }

  const getPlanPointers = () => {
    switch (planId) {
      case 1: //Look Sharp
      case 27: //Summer Look Sharp
        return [
          "Wash/dry/fold laundry; neatly folded and right-side-out.",
          "Professional dry cleaning.",
          "Gentle care washing, drying, and pressing.",
          "Khakis and polos returned on hangers.",
          "Launderable, button-down dress shirts and blouses returned pressed and on hangers.",
          "Laundering of personal AND/OR rental bed and bath.",
          "All bras, underwear, socks, camisoles, delicates, and bathing suits are always netted and wash/dried.",
          "Skirts, dresses, and dress pants returned pressed and on hangers.",
          "Comforter and blanket Plan FREE for a limited time.  ($199 value)",
          "Eco-Friendly Service. 86% less water, 64% less gas, and 80% less electricity.",
        ]
      case 2: //Just the Basics
      case 29: //Summer Just the Basics
        return [
          "Wash/dry/fold laundry; neatly folded and right-side-out.",
          "Khakis and polos returned on hangers.",
          "Launderable, button-down dress shirts returned pressed and on hangers.",
          "Laundering of personal AND/OR rental bed and bath.",
          "All bras, underwear, socks, camisoles, delicates, and bathing suits are always netted and wash/dried.",
          "Professional dry cleaning and gentle care washing NOT included.  Will incur a piece price charge.",
          "Eco-Friendly Service. 86% less water, 64% less gas, and 80% less electricity.",
        ]
      case 8: //Wash dry fold
      case 28: //Summer Wash dry fold
      case 37: //not 699 WDF
        return [
          "Wash/dry/fold laundry; neatly folded and right-side-out.",
          "Laundering of personal AND/OR rental bed and bath.",
          "All bras, underwear, socks, camisoles, delicates, and bathing suits are always netted and wash/dried.",
          "Professional dry cleaning and gentle care washing are NOT included.  Will incur a piece price charge.",
          "Eco-Friendly Service. 86% less water, 64% less gas, and 80% less electricity.",
        ]
      case 7: //Premium
        return [
          "Twice a week pickup and delivery service!",
          "Wash/dry/fold laundry; neatly folded and right-side-out.",
          "Professional dry cleaning.",
          "Gentle care washing, drying, and pressing.",
          "Khakis and polos returned on hangers.",
          "Launderable, button-down dress shirts and blouses returned pressed and on hangers.",
          "Laundering of personal AND/OR rental bed and bath.",
          "All bras, underwear, socks, camisoles, delicates, and bathing suits are always netted and wash/dried.",
          "Skirts, dresses, and dress pants returned pressed and on hangers.",
          "Comforter and blanket cleaning plan included.",
          "Eco-Friendly Service. 86% less water, 64% less gas, and 80% less electricity.",
        ]
      case 4: // Linen Rental
        return [
          'Four (4) flat sheets, two (2) pillowcases, and six (6) large (24 x 48") top quality bath towels to rent for the academic year and send in for weekly cleaning.',
        ]
      case 5: // Comforter
        return [
          "We will clean your own comforter and blanket up to eight (8) times each during the school year. If you are planning to send in a comforter and/or blanket for cleaning and the item will not fit in your E&R laundry bag, please contact our Customer Service Team ahead of time to request a comforter/blanket bag.",
        ]
      case 20: // Piece Price
        return [
          "Retail Dry Cleaning & Laundering Service at 1/3 off.",
          "Send in items on an as-needed basis. ",
          "A credit card is kept on file and billed on as needed.",
        ]
      case 21: // Blanket Rental
        return ["One (1) blanket to use for the entire summer session."]
      case 22: // Pillow
        return ["Includes one (1) disposable pillow."]
      default: //Other
        return []
    }
  }

  return (
    <div className={`burke-plan-component plan-box bdr-${getClassSuffix()}`}>
      <div className={`plan-box-header bg-${getClassSuffix()}`}>
        <div className="plan-box-title">
          {getHypeText() !== "" && (
            <>
              <span className={getClassSuffix()}>
                <i className="fa fa-star"></i>
                {getHypeText()}
              </span>
              {title}
            </>
          )}
          {getHypeText() === "" && title}
        </div>
      </div>
      <div className="plan-box-body">
        <ul className={`plan-box-list ${getClassSuffix}-list`}>
          {getPlanPointers().map((point) => (
            <li key={point}>{point}</li>
          ))}
        </ul>
        {planId === 8 && (
          <div className="plan-x">
            {" "}
            <img
              src="/images/plans/plan-x.png"
              alt="plan cost crossed out"
            />{" "}
          </div>
        )}
        {planId === 8 && (
          <div className="plan-box-price wdf plan-price-small">
            <span>$</span>849.00 x
          </div>
        )}
        {planId === 8 && <br />}
        <div className={`plan-box-price ${getClassSuffix()}`}>
          <span v-if="!hidePricing">$</span>
          {calculatePrice()}
        </div>
        {!hidePricing && isMonthly && (
          <div className="plan-box-sub-txt">Per Month</div>
        )}
        {!hidePricing && !isMonthly && (
          <div className="plan-box-sub-txt">Prices are prorated</div>
        )}
        {!hidePricing && (
          <div className="select-plan-btn-wrapper">
            <Button
              className="select-button"
              variant="contained"
              onClick={() => planPicked(id)}
            >
              Select Plan
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default BurkePlanComponent
