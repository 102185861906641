import { FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material"
import { StorePlan } from "../../../../apis/ersys-data"
import { Control } from "react-hook-form"
import FormInputNumber from "../../../forms/FormInputNumber"
import FormInputText from "../../../forms/FormInputText"
import FormInputCheckbox from "../../../forms/FormInputCheckbox"
import FormInputNumberIntToDollars from "../../../forms/FormInputNumberIntToDollars"

interface StorePlanPlanTabProps {
    formSpacing: number
    control: Control<StorePlan, any>
    showSkeleton: () => boolean
}

const StorePlanPlanTab: React.FC<StorePlanPlanTabProps> = ({ formSpacing, control, showSkeleton }) => {

    const renderSkeleton = () => {
        return (
            <FormGroup>
                {[...Array(5)].map((_, i) => (
                    <FormControlLabel
                        control={<Skeleton variant='rectangular' width={20} height={20} sx={{ marginRight: 2 }} />}
                        key={`skele-${i}`}
                        label={<Skeleton variant='rectangular' width={200} height={20} />}
                        sx={{ margin: 1.5 }} />
                )
                )}
            </FormGroup>
        )
    }

    return (
        <Grid container spacing={2} sx={{height: 400}} justifyContent="center">
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputText
                            spacing={formSpacing}
                            name={"publicName"}
                            control={control}
                            label={"Public Name"}
                            rules={{ maxLength: 90}}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isVisible"}
                            control={control}
                            label={"Is Visible"}
                        />
                        <FormInputNumberIntToDollars
                            spacing={formSpacing}
                            name={"currentCost"}
                            control={control}
                            label={"Cost"}
                        />
                        <FormInputNumberIntToDollars
                            spacing={formSpacing}
                            name={"storeCost"}
                            control={control}
                            label={"Store Cost"}
                        />
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"weekCount"}
                            control={control}
                            label={"Week Count"}
                            rules={{min: -1}}
                        />
												<FormInputCheckbox
                            spacing={formSpacing}
                            name={"monthlyChargedPlan"}
                            control={control}
                            label={"Charged Monthly"}
                        />

                    </FormGroup>)}
            </Grid>
            <Grid xs={6} item>
                {showSkeleton() ? renderSkeleton() :
                    (<FormGroup>
                        <FormInputNumber
                            spacing={formSpacing}
                            name={"visualOrder"}
                            control={control}
                            label={"VisualOrder"}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isFaculty"}
                            control={control}
                            label={"Faculty Plan"}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isRenewal"}
                            control={control}
                            label={"Renewal Plan"}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isProrationShutoff"}
                            control={control}
                            label={"Proration Shutoff"}
                        />
                        <FormInputCheckbox
                            spacing={formSpacing}
                            name={"isAvailableAsAddon"}
                            control={control}
                            label={"Available as Addon"}
                        />
                    </FormGroup>)}
            </Grid>
        </Grid>
    )
}

export default StorePlanPlanTab
