import { Grid, Paper, Typography } from "@mui/material"
import { MonthlyPayment } from "../../apis/ersys-data/api"
import dayjs from 'dayjs'
import "./AccountSubscriptionComponent.scss"

interface AccountSubscriptionComponentProps {
    payment: MonthlyPayment,
}

const AccountSubscriptionComponent: React.FC<AccountSubscriptionComponentProps> = ({ payment }) => {
    const formatDate = (d: string): string => {
        const date = dayjs(d)
        return date.format('MM/DD/YYYY')
    }

    const toDollars = (v: number): string => {
        return `$${(v / 100).toFixed(2)}`
    }

    const ordinalSuffixOf = (i: number) => {
        let j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    return (
        <Grid container component={Paper} sx={{ padding: 3, minWidth: 600 }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h6" className="no-margin" color="primary">Order: {payment.orderNumber}</Typography>
                </Grid>
                <Grid item xs={6} justifyContent={"right"}>
                    <Typography variant="h6" className="align-text-right" color="secondary">Subscribed on: {formatDate(payment.signUpDate)}</Typography>
                </Grid>
            </Grid>


            <Grid container sx={{marginTop: 5}}>
                <Grid item xs={6}>
                    <Typography variant="h6" className="no-margin">Charged on the {ordinalSuffixOf(payment.paymentDay)} of the month</Typography>
                </Grid>
                <Grid item xs={6} justifyContent={"right"}>
                    <Typography variant="h6" className="align-text-right" color="primary">Total: {toDollars(payment.amount)}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AccountSubscriptionComponent