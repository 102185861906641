import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { useTheme } from "@mui/material/styles"
import {
  DryCleaning,
  Person,
  Sell,
  CreditCard,
  PaymentSharp,
  PaymentsSharp,
  PaidSharp,
  AccountBalanceSharp,
} from "@mui/icons-material"

import "./SummaryStep.scss"
import PlanInfoLine from "../summary/PlanInfoLine/PlanInfoLine"
import { useEffect, useState } from "react"
import {
  DiscountCode,
  Store,
} from "../../../apis/ersys-public"
import {
  OrderRequest,
  OrderPlanRequest
} from "../../../apis/ersys-data"
import { showError } from "../../../features/errors/Errors"
import { setIsLoading } from "../../../features/menus/AppMenu"
import {
  setOrderStepIndex,
  setOrderPlan,
} from "../../../features/order-workflow/Order"
import { useErsysPublicApi } from "../../ErsysPublicApiProvider"
import PaymentPlanSection from "../summary/PaymentPlanSection/PaymentPlanSection"
import CreditCardComponent, {
  CreditCardToken,
} from "../payments/CreditCardComponent/CreditCardComponent"
import OrderCompleteModal from "../summary/OrderCompleteModal/OrderCompleteModal"
import { useFirebase } from "../../FirebaseProvider"
import GetMemoModal, { MemoRequest } from "./GetMemoModal"
import { useErsysDataApi } from "../../ErsysDataApiProvider"
import { usePermissions } from "../../usePermissions"
import FormInputNumberIntToDollars from "../../forms/FormInputNumberIntToDollars"
import { useForm } from "react-hook-form"

interface CustomDiscountData {
  value: number
}

const defaultCustomDiscount: CustomDiscountData = {
  value: 0
}

interface SummaryStepProps {
  currentIndex: number
}

const SummaryStep: React.FC<SummaryStepProps> = () => {
  const ersysPublicApiServices = useErsysPublicApi()
  const ersysDataApiServices = useErsysDataApi()
  const plan = useSelector((state: RootState) => state.order.selectedStorePlan)
  const account = useSelector((state: RootState) => state.order.selectedAccount)
  const orderPlan = useSelector((state: RootState) => state.order.orderPlan)
  const addons = useSelector((state: RootState) => state.order.selectedAddons)
  const isRenewal = useSelector((state: RootState) => state.order.isRenewal)
  const paymentPlan = useSelector(
    (state: RootState) => state.order.selectedPaymentPlan,
  )
  const isUpgrade = useSelector((state: RootState) => state.order.isUpgrade)
  const [store, setStore] = useState<Store>()
  const [showCreditCard, setShowCreditCard] = useState(false)
  const [showOrderComplete, setShowOrderComplete] = useState(false)
  const [showAddMemo, setShowAddMemo] = useState(false)
  const [currentPaymentType, setCurrentPaymentType] = useState("")
  const [orderId, setOrderId] = useState("")
  const [skipOrderConfirmation, setSkipOrderConfirmation] = useState(false)
  const [discountCodes, setDiscountCodes] = useState<DiscountCode[]>([])
  const [discountCode, setDiscountCode] = useState<string>("")
  const { user } = useFirebase()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { hasPermissions } = usePermissions()
  const { handleSubmit, control } =
    useForm<CustomDiscountData>({
      defaultValues: defaultCustomDiscount,
      reValidateMode: "onSubmit",
    })

  useEffect(() => {
    dispatch(setIsLoading(true))
    const fetch = async () => {
      if (account === undefined) {
        // return to select account step
        dispatch(setOrderStepIndex(0))
      }
      if (plan === undefined) {
        // return to select plan step
        dispatch(setOrderStepIndex(1))
      }

      try {
        // Get school name
        const storeResponse =
          await ersysPublicApiServices.storesService.storesGet(
            account!.storeId,
          )
        setStore(storeResponse.data)

        // Get order plan
        const newOrderPlanReq: OrderPlanRequest = {
          accountId: account!.id,
          paymentPlanId: paymentPlan?.id,
          plans: [
            {
              id: plan!.id,
              isAddon: false,
            },
            ...addons.map((a) => {
              return {
                id: a.storePlanId,
                isAddon: true,
              }
            }),
          ],
          discountCodes: discountCodes.map((d) => {
            return {
              code: d.code,
              value: parseInt(d.value.toString(), 10)
            }
          }),
        }

        // Create order plan
        const orderPlanResponse =
          await ersysDataApiServices.ordersService.ordersPlan(
            newOrderPlanReq,
          )
        dispatch(setOrderPlan(orderPlanResponse.data))
      } catch (ex: any) {
        dispatch(
          showError({
            title: "Unexpected Error occurred",
            message:
              "An unexpected error occurred while getting summary data. If problem persists please contact IT.",
          }),
        )
        console.log(ex)
      } finally {
        dispatch(setIsLoading(false))
      }
    }
    fetch()
  }, [
    dispatch,
    ersysPublicApiServices.storesService,
    ersysDataApiServices.ordersService,
    addons,
    discountCodes,
    paymentPlan?.id,
    account,
    plan,
  ])

  const orderType = () => {
    let ot = `os - ${user?.email ?? "undefined"}`
    if (ot.length > 25) {
      ot = ot.substring(0, 25)
    }

    return ot
  }

  const getDiscountCode = async (code: string) => {
    try {
      dispatch(setIsLoading(true))

      const resp = await ersysPublicApiServices.discountsService.discountsGet(
        code,
        store!.id,
        plan!.planId,
      )
      const newSet: DiscountCode[] = []
      newSet.push(...discountCodes)
      newSet.push(resp.data)
      setDiscountCodes(newSet)
      // Get order plan
      const newOrderPlanReq: OrderPlanRequest = {
        accountId: account!.id,
        paymentPlanId: paymentPlan?.id,
        plans: [
          {
            id: plan!.id,
            isAddon: false,
          },
          ...addons.map((a) => {
            return {
              id: a.storePlanId,
              isAddon: true,
            }
          }),
        ],
        discountCodes: discountCodes.map((d) => {
          return {
            code: d.code,
            value: parseInt(d.value.toString(), 10)
          }
        }),
      }

      // Create order plan
      const orderPlanResponse =
        await ersysDataApiServices.ordersService.ordersPlan(
          newOrderPlanReq,
        )
      dispatch(setOrderPlan(orderPlanResponse.data))
    } catch (ex) {
      dispatch(
        showError({
          title: "Discount Code not available",
          message: "That discount code was invalid.",
        }),
      )
    } finally {
      dispatch(setIsLoading(false))
      setDiscountCode("")
    }
  }

  const discountCodeOnEnter = async (
    e: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (e.key !== "Enter") {
      return
    }
    await getDiscountCode(discountCode)
  }

  const creditCardEntered = async (
    ccToken: CreditCardToken | undefined,
    errorMessage: string,
  ) => {
    setShowCreditCard(false)
    if (errorMessage !== "") {
      dispatch(
        showError({
          title: "Credit Card Error",
          message: errorMessage,
        }),
      )
      return
    }

    if (!ccToken) {
      dispatch(
        showError({
          title: "Unexpected Error",
          message:
            "Credit card token is null with no error message. Please Contact IT.",
        }),
      )
      return
    }

    await createOrder(ccToken!)
  }

  const memoComplete = async (req: MemoRequest) => {
    setShowAddMemo(false)
    await createOrder(undefined, currentPaymentType, req.memo)
  }

  const createOrder = async (
    ccToken: CreditCardToken | undefined,
    orderPaymentType: string = "cc",
    memo: string = "",
  ) => {
    try {
      dispatch(setIsLoading(true))
      const req: OrderRequest = {
        creditCardTokenDto: {
          brand: ccToken?.brand ?? "Not Set",
          expirationMonth: ccToken?.expMonth ?? 1,
          expirationYear: ccToken?.expYear ?? 1,
          lastFour: ccToken?.lastFour ?? 1,
          token: ccToken?.token ?? "Not Set",
        },
        isSilent: skipOrderConfirmation,
        isRenewal: isRenewal,
        isUpgrade: isUpgrade,
        needsBag: true,
        paymentMemo: memo,
        orderType: orderType(),
        paymentType: orderPaymentType,
        order: {
          accountId: account!.id,
          paymentPlanId: paymentPlan?.id,
          plans: [
            {
              id: plan!.id,
              isAddon: false,
            },
            ...addons.map((a) => {
              return {
                id: a.storePlanId,
                isAddon: true,
              }
            }),
          ],
          discountCodes: discountCodes.map((d) => {
            return {
              code: d.code,
              value: parseInt(d.value.toString(), 10)
            }
          }),
        },
      }
      const resp = await ersysDataApiServices.ordersService.ordersCreate(req)
      setOrderId(resp.data.id.toString())
      setShowOrderComplete(true)
    } catch (ex: any) {
      if (ex.response && ex.response.data) {
        dispatch(
          showError({
            title: ex.response.data.title,
            message: ex.response.data.detail,
          }),
        )
      } else {
        dispatch(
          showError({
            title: "Unexpected Error Occurred",
            message: "Unexpected Error. If problem persists please inform IT.",
          }),
        )
      }
      console.log(ex)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const onOrderCompleteClose = async () => {
    dispatch(setOrderStepIndex(0))
  }

  const handeCreateCustomDiscount = (input: CustomDiscountData) => {
    console.log(input)
    setDiscountCodes([...discountCodes.filter(c => c.code !== 'custom'),
    {
      code: 'custom',
      value: input.value,
      description: 'Custom Discount',
      isPercentage: false,
      isPercentOfTotalOrder: false
    }
    ])
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: theme.spacing(2) }}>
      <CreditCardComponent open={showCreditCard} onClose={creditCardEntered} />
      <GetMemoModal onClose={() => setShowAddMemo(false)} onCreate={memoComplete} open={showAddMemo} />
      <OrderCompleteModal
        orderId={orderId}
        accountId={account?.id.toString() ?? ""}
        onClose={onOrderCompleteClose}
        open={showOrderComplete}
      />
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Card sx={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
              title="Student Info"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              titleTypographyProps={{
                fontWeight: theme.typography.fontWeightBold,
              }}
              avatar={<Person />}
            />
            <CardContent>
              <ul className="no-style-list">
                <li>
                  <Typography>
                    {account!.primaryFirstName} {account!.primaryLastName}
                  </Typography>
                </li>
                <li>
                  <Typography>{account!.primaryPhone}</Typography>
                </li>
                <li>
                  <Typography>{account!.primaryEmail}</Typography>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card sx={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
              title="Billing Info"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              titleTypographyProps={{
                fontWeight: theme.typography.fontWeightBold,
              }}
              avatar={<CreditCard />}
            />
            <CardContent>
              <ul className="no-style-list">
                <li>
                  <Typography>
                    {account!.billingFirstName} {account!.billingLastName}
                  </Typography>
                </li>
                <li>
                  <Typography>{account!.billingAddressLineOne}</Typography>
                </li>
                {account!.billingAddressLineTwo && (
                  <li>
                    <Typography>{account!.billingAddressLineTwo}</Typography>
                  </li>
                )}
                <li>
                  <Typography>
                    {account!.billingCity}, {account!.billingState}{" "}
                    {account!.billingPostal}
                  </Typography>
                </li>
                <li>
                  <Typography>{account!.billingPhone}</Typography>
                </li>
                <li>
                  <Typography>{account!.billingEmail}</Typography>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card sx={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
              title="Discounts"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              titleTypographyProps={{
                fontWeight: theme.typography.fontWeightBold,
              }}
              avatar={<Sell />}
            />
            <CardContent>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  label="Discount Code Entry"
                  placeholder="SAVE20"
                  type="string"
                  sx={{ maxWidth: "100%" }}
                  onKeyDown={discountCodeOnEnter}
                />
              </FormControl>
            </CardContent>
          </Card>

          { hasPermissions("AP:OF:D++") && 
          <Card sx={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
              title="Custom Discount"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              titleTypographyProps={{
                fontWeight: theme.typography.fontWeightBold,
              }}
              avatar={<Sell />}
            />
            <CardContent>
              <FormControl sx={{ width: "100%" }}>
                <FormInputNumberIntToDollars
                  control={control}
                  label="Amount ($)"
                  name="value"
                  spacing={2}
                />
              </FormControl>
              <div className="select-account-btn-wrapper">
              <Button onClick={handleSubmit(handeCreateCustomDiscount)}>
                Add Custom Discount
              </Button>
              </div>
            </CardContent>
          </Card> }
          
        </Grid>
        <Grid xs={6} item>
          <Card sx={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
              title="Plan Info"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              titleTypographyProps={{
                fontWeight: theme.typography.fontWeightBold,
              }}
              avatar={<DryCleaning />}
            />
            <CardContent>
              <ul className="no-style-list">
                {orderPlan &&
                  orderPlan!.plans.map((p) => (
                    <li key={p.storePlanId}>
                      <PlanInfoLine label={p.name} value={p.value ?? 0} />
                    </li>
                  ))}
                <hr />
                <li>
                  <PlanInfoLine
                    label="Subtotal"
                    value={orderPlan?.subtotal ?? 0}
                  />
                </li>
                <hr />
                {orderPlan &&
                  orderPlan!.fees.map((f) => (
                    <li id={`fee-${f.name}`}>
                      <PlanInfoLine
                        label={f.name ?? "Unknown Fee"}
                        value={f.value ?? 0}
                      />
                    </li>
                  ))}
                {orderPlan && orderPlan!.fees && orderPlan!.fees.length > 0 && (
                  <hr />
                )}
                {orderPlan &&
                  orderPlan!.credits.map((c) => (
                    <li id={`credits-${c.name}`}>
                      <PlanInfoLine
                        label={c.name ?? "Unknown Credit"}
                        value={c.value ?? 0}
                      />
                    </li>
                  ))}
                {orderPlan &&
                  orderPlan!.credits &&
                  orderPlan!.credits.length > 0 && <hr />}
                {orderPlan &&
                  orderPlan!.discounts.map((d) => (
                    <li id={`discount-${d.description}`}>
                      <PlanInfoLine
                        label={d.description}
                        value={(d.value ?? 0) * -1}
                      />
                    </li>
                  ))}
                {orderPlan &&
                  orderPlan!.discounts &&
                  orderPlan!.discounts.length > 0 && <hr />}
                <li>
                  <PlanInfoLine label="Total" value={orderPlan?.total ?? 0} />
                </li>
                <li>
                  <PlanInfoLine
                    label="Due Today"
                    value={orderPlan?.payments[0].value ?? 0}
                  />
                </li>
              </ul>
            </CardContent>
          </Card>
          {paymentPlan && paymentPlan.id !== -1 && (
            <PaymentPlanSection paymentPlan={orderPlan?.payments ?? []} />
          )}
          {account?.creditCardToken && (
            <Card>
              <CardHeader
                title="Card on File"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
                titleTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
                avatar={<CreditCard />}
              />

              <CardContent>
                <ul className="no-style-list">
                  <li>
                    <Typography>
                      {account!.creditCardToken!.brand}: xxxx xxxx xxxx{" "}
                      {account!.creditCardToken!.lastFour}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      exp:{" "}
                      {account!.creditCardToken!.expirationMonth.toLocaleString(
                        "en-US",
                        { minimumIntegerDigits: 2, useGrouping: false },
                      )}
                      /
                      {account!.creditCardToken!.expirationYear.toLocaleString(
                        "en-US",
                        { minimumIntegerDigits: 4, useGrouping: false },
                      )}
                    </Typography>
                  </li>
                </ul>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={4}>
          {hasPermissions("AP:OF:MCC") &&
            <div className="create-order-btn-wrapper">
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setCurrentPaymentType("manualcc")
                  setShowAddMemo(true)
                }}
                endIcon={<AccountBalanceSharp />}
              >
                Order With Manual CC
              </Button>
            </div>
          }
        </Grid>
        <Grid item sm={4}>
          {hasPermissions("AP:OF:CA") &&
            <div className="create-order-btn-wrapper">
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setCurrentPaymentType("cash")
                  setShowAddMemo(true)
                }}
                endIcon={<PaidSharp />}
              >
                Order With Cash
              </Button>
            </div>
          }
          {hasPermissions("AP:OF:CH") &&
            <div className="create-order-btn-wrapper">
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setCurrentPaymentType("check")
                  setShowAddMemo(true)
                }}
                endIcon={<AccountBalanceSharp />}
              >
                Order With Check
              </Button>
            </div>
          }
        </Grid>
        <Grid item sm={4}>
          <div className="create-order-btn-wrapper">
            <Button
              variant="contained"
              color={account?.creditCardToken ? "warning" : "primary"}
              onClick={() => setShowCreditCard(true)}
              endIcon={<PaymentSharp />}
            >
              Order With New Card
            </Button>
          </div>

          {account?.creditCardToken && (
            <div className="create-order-btn-wrapper">
              <Button
                variant="contained"
                onClick={() => createOrder(undefined, "cconfile")}
                endIcon={<PaymentsSharp />}
              >
                Order With Card On File
              </Button>
            </div>
          )}
        </Grid>
          <Grid item sm={4}>
            {hasPermissions("AP:OF:SO") &&
                <FormControlLabel
                        control={<Checkbox />}
                        label="Skip Confirmation Email"
                        checked={skipOrderConfirmation}
                        onChange={(e: any) => { setSkipOrderConfirmation(e.target.checked) }}
                    />
            }
          </Grid>
      </Grid>
    </Container>
  )
}

export default SummaryStep
