import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { useEffect, useState } from "react"
import {
  setIsUpgrade,
  setOrderStepIndex,
  setProration,
  setSelectedStorePlan,
} from "../../../features/order-workflow/Order"
import { useErsysPublicApi } from "../../ErsysPublicApiProvider"
import { showError } from "../../../features/errors/Errors"
import { Store, StorePlan } from "../../../apis/ersys-public"
import { setIsLoading } from "../../../features/menus/AppMenu"

import "./PlansStep.scss"
import BurkePlanComponent from "../BurkePlanComponent/BurkePlanComponent"
import { prorate } from "../../../functions/proration"

interface PlansStepProps {
  currentIndex: number
}

const PlansStep: React.FC<PlansStepProps> = ({ currentIndex }) => {
  const ersysPublicApiServices = useErsysPublicApi()
  const currentAccount = useSelector(
    (state: RootState) => state.order.selectedAccount,
  )
  const accountNeedsCreation = useSelector(
    (state: RootState) => state.order.accountNeedsCreation,
  )
  const accountCreationType = useSelector(
    (state: RootState) => state.order.accountCreationType,
  )
  const selectedStore = useSelector(
    (state: RootState) => state.order.selectedStore,
  )
  const proration = useSelector((state: RootState) => state.order.proration)
  const isRenewal = useSelector((state: RootState) => state.order.isRenewal)
  const [store, setStore] = useState<Store | undefined>(undefined)
  const [storePlans, setStoreplans] = useState<StorePlan[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsLoading(true))
    const fetch = async () => {
      if (currentAccount === undefined && !accountNeedsCreation) {
        // return to select account step
        dispatch(setOrderStepIndex(0))
      }

      try {
        // Get school name
        const storeResponse =
          await ersysPublicApiServices.storesService.storesGet(
            selectedStore!,
          )
        setStore(storeResponse.data)

        // Get Store Proration
        const prorationResponse =
          await ersysPublicApiServices.storesService.storesGetCurrentProration(
            selectedStore!,
          )
        dispatch(setProration(prorationResponse.data))

        // Get plans
        //todo: this locks it into renewals only!!!
        const plansResponse =
          await ersysPublicApiServices.storesService.storesListPlans(
            selectedStore!,
            currentAccount?.id.toString(),
            isRenewal.toString(),
            (
              (currentAccount?.accountType ?? accountCreationType) === "faculty"
            ).toString(),
          )
        const orderedPlans = plansResponse.data.plans.sort((a, b) =>
          (a.visualOrder ?? 0) > (b.visualOrder ?? 0)
            ? 1
            : a.visualOrder === b.visualOrder
              ? (a.visualOrder ?? 0) > (b.visualOrder ?? 0)
                ? 1
                : -1
              : -1,
        )
        setStoreplans(orderedPlans)
        dispatch(setIsUpgrade(plansResponse.data.mode === "upgrade"))
      } catch (ex: any) {
        dispatch(
          showError({
            title: "Unexpected Error occurred",
            message:
              "An unexpected error occurred while getting plan data. If problem persists please contact IT.",
          }),
        )
        console.log(ex)
      } finally {
        dispatch(setIsLoading(false))
      }
    }

    fetch()
  }, [
    isRenewal,
    currentAccount,
    accountNeedsCreation,
    accountCreationType,
    selectedStore,
    dispatch,
    ersysPublicApiServices.storesService,
    ersysPublicApiServices.plansService,
  ])

  const planPicked = async (id: number) => {
    dispatch(setIsLoading(true))
    const selectedPlans = storePlans.filter((p) => p.id === id)
    if (selectedPlans.length === 0) {
      console.log("attempted to select non-existant plan... skipping.")
      dispatch(setIsLoading(false))
      return
    }
    dispatch(setSelectedStorePlan(selectedPlans[0]))
    dispatch(setOrderStepIndex(currentIndex + 1))
  }

  return (
    <>
      {store && (
        <h2 className="plans-header">
          {currentAccount?.primaryFirstName}{" "}
          {currentAccount?.primaryLastName ?? "New Account"} : {store.fullName}
        </h2>
      )}
      <div className="plan-container">
        {(!storePlans || storePlans.length === 0) && (
          <h3>
            No plans are currently available for this account to either order or
            upgrade to.
          </h3>
        )}
        {storePlans.length > 0 &&
          storePlans.map((plan) => (
            <BurkePlanComponent
              key={plan.id}
              id={plan.id}
              planId={plan.planId!}
              cost={prorate(plan.currentCost!, proration, !(store?.prorationEnabled ?? false) || (plan.isProrationShutoff ?? false) || (plan.monthlyChargedPlan ?? false))}
              hidePricing={false}
              isMonthly={plan.monthlyChargedPlan ?? false}
              title={plan.publicName!}
              planPicked={planPicked}
            />
          ))}
      </div>
    </>
  )
}

export default PlansStep
