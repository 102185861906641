import { Card, CardHeader, Checkbox } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Proration, StorePlanAddon } from "../../../apis/ersys-public"

import "./AddonComponent.scss"
import { prorate } from "../../../functions/proration"
import React from "react"

interface AddonComponentProps {
  addon: StorePlanAddon
  proration: Proration
  isProrationShutoff: Boolean,
  onChecked: (id: number) => void
  onUnchecked: (id: number) => void
}

const AddonComponent: React.FC<AddonComponentProps> = ({
  addon,
  proration,
  isProrationShutoff,
  onChecked,
  onUnchecked,
}) => {
  const theme = useTheme()
  const [isChecked, setChecked] = React.useState(addon.currentCost === 0)

  const calculatePrice = () => {
    if (addon.currentCost === undefined || isNaN(addon.currentCost)) {
      return "0"
    }

    return `${(prorate(addon.currentCost, proration, isProrationShutoff) / 100).toFixed(2)}`
  }

  const onChange = (checked: boolean) => {
    if (checked) {
      onChecked(addon.id)
      return
    }
    onUnchecked(addon.id)
  }

  return (
    <Card>
      <CardHeader
        title={`${addon.publicName}: $${calculatePrice()}`}
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
        onClick={() => {
          setChecked(!isChecked)
          onChange(!isChecked)
        }}
        titleTypographyProps={{ fontWeight: theme.typography.fontWeightBold }}
        avatar={
          <Checkbox
            checked={isChecked}
            onChange={() => {
              setChecked(!isChecked)
              onChange(!isChecked)
            }}
            sx={{
              color: theme.palette.primary.contrastText,
              "&.Mui-checked": { color: theme.palette.primary.contrastText },
            }}
          />
        }
      />
      {/* <CardContent>
                <Typography>{addon.description}</Typography>
            </CardContent> */}
    </Card>
  )
}

export default AddonComponent
